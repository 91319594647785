import React from 'react';
import Preloader from './components/Preloader';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AboutSection from './components/AboutSection';
import SkillsSection from './components/SkillsSection';
// import FeatureSection from './components/FeatureSection';
// import ClientSection from './components/ClientSection';
// import PricingSection from './components/PricingSection';
import WorkSection from './components/WorkSection';
import ContactSection from './components/ContactSection';
// import Footer from './components/Footer';
// import FooterAlt from './components/FooterAlt';


class Index6 extends React.Component {

  componentDidMount() {
    document.getElementById("main_navbar").classList.add("navbar-light");
  }

  render() {

    return (
      <React.Fragment>

        {/* preloader */}
        <Preloader />

        {/* Navigation Menu */}
        <Navbar />

        {/* HomeSection Menu */}
        <Home />

        {/* AboutSection Menu */}
        <AboutSection />

        {/* SkillsSection Menu */}
        <SkillsSection />

        {/* FeatureSection Menu */}
        {/* <FeatureSection /> */}

        {/* PricingSection Menu */}
        {/* <PricingSection /> */}

        {/* ClientSection Menu */}
        {/* <ClientSection /> */}

        {/* WorkSection Menu */}
        {/* <WorkSection /> */}

        {/* ContactSection Menu */}
        <ContactSection />

        {/* Footer Menu */}
        {/* <Footer /> */}

        {/* FooterAlt Menu */}
        {/* <FooterAlt /> */}

      </React.Fragment>

    );
  }
}

export default Index6;