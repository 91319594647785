import React from 'react';
import { Row, Col } from 'reactstrap';

class WorkSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            WorkList: [{
                "image_src": "images/recent-work/Neighbr-1.png",
                "title": "Neighbr",
                "content": "is a forum driven by your community.",
                "links": [{link: "https://neighbr.herokuapp.com/", "description": "Demo"}, {link: "https://github.com/Cerulean-Sea/Neighbr", "description": "GitHub"}]
            },
            {
                "image_src": "images/recent-work/Fitlog-1.png",
                "title": "Fitlog",
                "content": "is a web application for gym-goers to create their own personalized workout programs and track their progress.",
                "links": [{link: "https://fitlogx.herokuapp.com/", "description": "Demo"}, {link: "https://github.com/andyqvo/FitLog", "description": "GitHub"}]
            },
            {
                "image_src": "images/recent-work/Catbird-1.png",
                "title": "Catbird",
                "content": "is a complete re-design and overhaul over its predecessor, Birdcat, using modern technologies and design elements with a refreshing and intuitive user interface.",
                "links": [{link: "https://github.com/Project-Catbird/Catbird", "description": "Front End"}, {link: "https://github.com/Project-Catbird/SDC-QnA", "description": "Back End"}]
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section" id="work">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Work</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <div className="row">

                            {this.state.WorkList.map((work, index)=> {
                                return <Col lg="4" className="d-flex" key={index}>
                                    <div className="work position-relative d-flex flex-column align-items-stretch">
                                        <div className="work-img position-relative mt-4">
                                            <img src={work.image_src} alt="" className="img-fluid mx-auto d-block rounded" />
                                        </div>
                                        <div className="position-relative flex-grow-1">
                                            <div className="work-content bg-white p-4 h-100">
                                                <div className="work-description">
                                                    <h5 className="font-weight-normal f-18">{work.title}</h5>
                                                    <p className="text-muted f-14">{work.content}</p>
                                                </div>
                                                <div className="work-read-more">
                                                    <div className="work-list d-flex">
                                                    {work.links.map((link, index) => (
                                                        <div className="work-item" key={index}>
                                                            <a href={link.link} className="text-primary f-15 mr-2" target="_blank" rel="noopener noreferrer">{link.description}</a>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            })}

                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default WorkSection;