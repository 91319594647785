import React from 'react';
import { Row, Col } from 'reactstrap';

import javascript from "./images/javascript.svg";
import react from "./images/react.svg";
import redux from "./images/redux.svg";
import material from "./images/material-ui.svg";
import bootstrap from "./images/bootstrap.svg";
import nodejs from "./images/nodejs.svg";
import mongodb from "./images/mongodb.svg";
import express from "./images/express.svg";
import mysql from "./images/mysql.svg";
import psql from "./images/psql.svg";
import nginx from "./images/nginx.svg";
import git from "./images/git.svg";
import npm from "./images/npm.svg";
import jest from "./images/jest.svg";
import docker from "./images/docker.svg";
import aws from "./images/aws.svg";
import jquery from "./images/jquery.svg";
import python from "./images/python.svg";
import graphql from "./images/graphql.svg";
import webpack from "./images/webpack.svg";
import css3 from "./images/css3.svg";

class SkillsSection extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            SkillsList: [
            {
                "icon": "mdi mdi-palette",
                "title": "Front-End",
                "content": [
                    { img: javascript, language: "JavaScript" },
                    { img: react, language: "React" },
                    { img: redux, language: "Redux" },
                    { img: jquery, language: "JQuery" },
                    { img: material, language: "Material-UI" },
                    { img: bootstrap, language: "Bootstrap" },
                    { img: css3, language: "CSS3" },
                  ],
            },
            {
                "icon": "mdi mdi-database",
                "title": "Back-End",
                "content": [
                    { img: nodejs, language: "Node.js" },
                    { img: python, language: "Python" },
                    { img: express, language: "Express" },
                    { img: graphql, language: "GraphQL" },
                    { img: mongodb, language: "MongoDB" },
                    { img: mysql, language: "MySQL" },
                    { img: psql, language: "PostgreSQL" },
                  ],
            },
            {
                "icon": "mdi mdi-hexagon-multiple",
                "title": "Other",
                "content": [
                    { img: git, language: "Git" },
                    { img: npm, language: "NPM" },
                    { img: jest, language: "Jest" },
                    { img: webpack, language: "Webpack" },
                    { img: docker, language: "Docker" },
                    { img: nginx, language: "NGNIX" },
                    { img: aws, language: "AWS" },
                  ],
            },
            ]
        };
    }

    render() {

        return (
            <React.Fragment>
                <section className="section" id="skills">
                    <div className="container">
                        <Row>
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Technical Skills</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            {this.state.SkillsList.map((skill, index) => {
                                return <Col lg="4" md="6" key={index}>
                                    <div className="skill-box rounded mt-4 p-4">
                                        <div className="skill-icon mb-3">
                                            <i className={skill.icon}></i>
                                        </div>
                                        <div className="skills-desc">
                                            <div className="skill-title mb-2 position-relative">
                                                <h5 className="font-weight-normal mb-5">{skill.title}</h5>
                                            </div>
                                            <div className="text-muted mb-3 f-14">
                                                <div className="skills-subtitle">
                                                    {skill.content.map((el, index) => {
                                                    return (
                                                        <div key={index} className="middle">
                                                            <img style={{height: '48px'}} src={el.img} alt="# " />
                                                            <p>{el.language}</p>
                                                        </div>
                                                    );
                                                    })}
                                                </div>
                                            </div>
                                            {/* <p className="mb-0 text-uppercase f-13"><Link to="/" className="text-primary">learn more<i className="mdi mdi-arrow-right ml-2"></i></Link></p> */}
                                        </div>
                                    </div>
                                </Col>
                            })}
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default SkillsSection;