import React from 'react';
import { Row, Col } from 'reactstrap';

class ContactSection extends React.Component {
    render() {

        return (
            <React.Fragment>
                <section className="section bg-light" id="contact">
                    <div className="container">
                        <div className="row">
                            <Col lg="12">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">Contact</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </div>

                        <Row>
                            <Col lg="12">
                                <div className="contact-box p-5">
                                    <Row className="justify-content-center">

                                        <div className="col-lg-4 col-md-6">
                                                <div className="follow">
                                                    <ul className="follow-icon list-inline mt-32 mb-0 text-center">
                                                        <li className="list-inline-item">
                                                            <a href="mailto:andyqvo@gmail.com" target="_blank" rel="noopener noreferrer" className="social-icon text-muted"><i className="mdi mdi-email-outline"></i></a>
                                                        </li>&nbsp;
                                                        <li className="list-inline-item">
                                                            <a href="https://www.linkedin.com/in/andyqvo/" target="_blank" rel="noopener noreferrer" className="social-icon text-muted"><i className="mdi mdi-linkedin"></i></a>
                                                        </li>&nbsp;
                                                        <li className="list-inline-item">
                                                            <a href="https://github.com/andyqvo" target="_blank" rel="noopener noreferrer" className="social-icon text-muted"><i className="mdi mdi-github-circle"></i></a>
                                                        </li>&nbsp;
                                                    </ul>
                                                </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default ContactSection;