import React from 'react';
import { Row, Col } from 'reactstrap';

class AboutSection extends React.Component {

    render() {

        return (
            <React.Fragment>
                <section className="section bg-about bg-light-about bg-light" id="about">
                    <div className="container">
                        <Row>
                            <Col lg="4">
                                <div className="title-heading mb-5">
                                    <h3 className="text-dark mb-1 font-weight-light text-uppercase">About Me</h3>
                                    <div className="title-border-simple position-relative"></div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="align-items-center">
                            <Col>
                                <div className="about-desc">
                                    <p className="text-muted">Hi, my name is Andy Vo. I'm a full-stack software engineer from Los Angeles, CA. Prior to my transition to software engineering, I graduated college with a degree in Finance and spent about 5 years working in finance where I focused on financial planning and wealth management. At my last job, I taught myself how to program to help with my equity research and I fell in love with programming. In early 2021, I decided to take a leap of faith and attend a software engineering bootcamp with Hack Reactor. I'm passionate about building tools and developing methods for people to work more efficiently and effectively.</p>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default AboutSection;