import React from 'react';
import { Row, Col } from 'reactstrap';

class Home extends React.Component {
    render() {

        return (
            <React.Fragment>
                   <section className="section home-6-bg" id="home">
                        <div className="home-center">
                            <div className="home-desc-center">
                                <div className="container">
                                    <Row className="align-items-center">
                                        <Col lg="6">
                                            <div className="mt-40 home-5-content">
                                                <h1 className="text-white font-weight-light home-6-title mb-0">Hi, my name is Andy Vo.</h1>
                                                <p className="text-white-70 font-weight-light mt-4 mb-0">I'm a full-stack software engineer from Los Angeles, CA.</p>
                                                <div className="about-links mt-4">
                                                    <div className="follow">
                                                        <ul className="follow-icon list-inline mt-32 mb-0">
                                                            <li className="list-inline-item">
                                                                <a href="mailto:andyqvo@gmail.com" target="_blank" rel="noopener noreferrer" className="social-icon text-muted text-center"><i className="mdi mdi-email-outline"></i></a>
                                                            </li>&nbsp;
                                                            <li className="list-inline-item">
                                                                <a href="https://www.linkedin.com/in/andyqvo/" target="_blank" rel="noopener noreferrer" className="social-icon text-muted text-center"><i className="mdi mdi-linkedin"></i></a>
                                                            </li>&nbsp;
                                                            <li className="list-inline-item">
                                                                <a href="https://github.com/andyqvo" target="_blank" rel="noopener noreferrer" className="social-icon text-muted text-center"><i className="mdi mdi-github-circle"></i></a>
                                                            </li>&nbsp;
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>

                                        <Col lg="6">
                                            <div className="home-5-content home-6-content mt-40 p-4">
                                                <img src="/images/prof.png" alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="container-fluid">
                                    <Row className="row">
                                        <div className="home-1-shape">
                                            <img src="images/home-6-shape.png" alt="" className="img-fluid mx-auto w-100 d-block" />
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </section>
            </React.Fragment>
        );
    }
}
export default Home;