import React from 'react';
import ReactDOM from 'react-dom';
import Index6 from './Index6';
import * as serviceWorker from './serviceWorker';

class Root extends React.Component {

    render() {
        return(
            <>
                <Index6/>
            </>
        );
    }
   }

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
